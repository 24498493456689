<template>
  <div class="background-content">
    <sidebar></sidebar>

    <navbar-user class="hidden"></navbar-user>


    <v-breadcrumbs style="margin-bottom: -30px;" class="mt-16 pb-0 ml-3 hidden" :items="items" large>
         <template v-slot:item="{ item }">
        <v-breadcrumbs-item style="cursor: pointer;"  :to="{name: item.href}" :active-class="item.active" exact :disabled="item.disabled"   >
          <!--  :href="item.href" -->
          <template v-if="item.icon">
            <v-icon style="font-size: 22px; color: #b4b4b4" large>
              mdi-home-outline
            </v-icon>
          </template>
          <template v-else>
           <p class="mb-0" style="color: #466be3"> {{ item.text }} </p>  
          </template>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <v-main class="pl-0 pt-0 ml-1">
      <!-- <v-container fluid> -->
      <router-view></router-view>
      <!-- </v-container> -->
    </v-main>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import NavbarUser from "@/components/NavbarUser.vue";
export default {
  components: {
    Sidebar,
    NavbarUser,
  },
  watch: {
    $route: function (n) {
      this.items = n.meta.breadcrums;
    },
  },
  data() {
    return {
        items1: [
        {
          text: 'Dashboard',
          disabled: false,
          href: 'home',
        },
        {
          text: 'Link 1',
          disabled: false,
          href: 'breadcrumbs_link_1',
        },
        {
          text: 'Link 2',
          disabled: true,
          href: 'breadcrumbs_link_2',
        },
      ],
      items: [],
    };
  },
  mounted() {
    this.items = this.$route.meta.breadcrums;
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/main";
.background-content {
  min-height: 100vh;
  background-color: #f6f9ff !important
}

@media only screen and (max-width: 1200px) {
  .breadcrumbs__hidden {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .hidden {
    display: none;
  }

  .v-main {
    margin-left: 0 !important;
    padding: 0 !important;
  }
}
</style>
