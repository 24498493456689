export const navigation = {
  pathUser: [
    {
      id: 1,
      routeName: "home",
      text: "Inicio",
      name: "Inicio",
      count: true,
      subItems: [],
      icon: "mdi-home-outline",
      img: origin + "/icon/home.svg",
      img2: origin + "/icon/home-2.svg",
      press: "",
      class: "v-step-0",
      classIcon: "home-while",
      show: true,
      admName: 'Okan home',
      admId: 344
    },
    {
      id: 2,
      routeName: "listNews",
      text: "Actualidad BRM",
      count: true,
      subItems: [],
      name: "Actualidad BRM",
      img: origin + "/icon/archive-book.svg",
      icon: "mdi-newspaper-variant-multiple",
      press: "",
      class: "v-step-6 ",
      show: true,
      classIcon: "news-while",
      admName: 'Okan Actualidad',
      admId: 345
    },
    {
      id: 13,
      routeName: "podcast",
      text: "Pódcast",
      count: true,
      subItems: [],
      name: "Pódcast",
      img: origin + "/icon/microphone.svg",
      press: "",
      show: true,
      classIcon: "microphone-white",
      admName: 'Okan Podcast',
      admId: 346
    },
    // {
    //     id: 11,
    //     routeName: "commissions",
    //     text: "Comisiones",
    //     count: true,
    //     subItems: [],
    //     name: "Comisiones",
    //     img: origin + "/icon/comission.svg",
    //     icon: "",
    //     press: "",
    //     class:"",
    //     show: true,
    //     classIcon: "commision-while",
    // },
    // {
    //     id: 3,
    //     routeName: "laborRelations",
    //     text: "Relaciones laborales",
    //     count: true,
    //     subItems: [],
    //     icon: "mdi-chart-box-outline",
    //     img: origin + "/icon/chart-while.svg",
    //     classIcon: "chart-while",
    // },
    {
      id: 4,
      routeName: "apps",
      text: "Aplicativos",
      count: true,
      subItems: [],
      icon: "mdi-apps",
      name: "Aplicativos",
      img: origin + "/icon/more-Desktop.svg",
      press: "",
      class: "v-step-10 ",
      classIcon: "apps-while",
      show: true,
      admName: 'Okan aplicativos',
      admId: 347
    },
    { //ESteban ajustar
      id: 16,
      routeName: "carnet",
      text: "Codigo carnet",
      count: true,
      subItems: [],
      icon: "user-cirlce-add", // Definir
      name: "Codigo carnet",
      img: origin + "/icon/update-grey.svg",
      press: "",
      class: "v-step-17",
      show: false,   //Esteban
      classIcon: "user-cirlce-add-while",
      admId: 439 //poner despues 
    },
    {
      id: 10,
      routeName: "documents",
      text: "Documentos corporativos",
      count: true,
      subItems: [],
      icon: "mdi-clipboard-text-outline",
      name: "Documentos corporativos",
      img: origin + "/icon/document-text.svg",
      press: "",
      show: true,
      class: "v-step-28",
      classIcon: "document-while",
      admName: 'Okan documentos',
      admId: 348
      // press: 'showApps'
    },
    {
      id: 5,
      routeName: "forms",
      text: "BRM Pregunta",
      count: true,
      subItems: [],
      icon: "mdi-clipboard-text-outline",
      name: "BRM Pregunta",
      img: origin + "/icon/task-square.svg",
      press: "",
      show: true,
      class: "v-step-25",
      classIcon: "forms-while",
      admName: 'Okan formularios',
      admId: 349
      // press: 'showApps'
    },
    {
      id: 9,
      routeName: "actionToday",
      text: "Solicitudes",
      count: true,
      subItems: [],
      icon: "mdi-logout-variant",
      name: "Solicitudes",
      img: origin + "/icon/message-question.svg",
      press: "",
      show: true,
      class: "v-step-31",
      classIcon: "question-while",
      admName: 'Okan acciones',
      admId: 350
    },
    {
      id: 6,
      routeName: "programsBrm",
      text: "Programas BRM",
      count: true,
      subItems: [],
      icon: "mdi-book-check-outline",
      name: "Programas BRM",
      img: origin + "/icon/magic-star.svg",
      class: "v-step-15",
      show: true,
      classIcon: "star-while",
      admName: 'Okan Programas BRM',
      admId: 351
    },
    {
      id: 18,
      routeName: "tyc",
      text: "T&C",
      count: true,
      subItems: [],
      icon: "mdi-clipboard-text-outline",
      name: "Reglamentos y Políticas",
      img: origin + "/icon/formularios-1.svg",
      press: "",
      show: true,
      class: "v-step-25",
      classIcon: "tyc-while", 
      // admId: 349
      // press: 'showApps'
    },
    // {
    //   id: 6,
    //   routeName: "lifeControl",
    //   text: "control de vida",
    //   count: true,
    //   subItems: [],
    //   icon: "mdi-book-check-outline",
    //   name: "control de vida",
    //   img: origin + "/icon/magic-star.svg",
    //   class: "v-step-15",
    //   show: true,
    //   classIcon: "star-while",
    //   admName: 'Okan control de vida',
    //   admId: 351
    // },
    {
      id: 7,
      routeName: "profile",
      text: "Mi perfil empresarial",
      count: true,
      subItems: [],
      icon: "mdi-account-box-outline",
      name: "Mi perfil empresarial",
      img: origin + "/icon/user-square.svg",
      press: "",
      class: "v-step-17",
      show: true,
      classIcon: "profile-while",
      admName: 'Okan perfil',
      admId: 352
    },
    {
      id: 12,
      routeName: "referenceReport",
      text: "Referidos",
      count: true,
      subItems: [],
      icon: "mdi-account-box-outline",
      name: "Referidos",
      img: origin + "/icon/reference.svg",
      press: "",
      class: "v-step-17",
      show: false,
      classIcon: "reference-while",
      admName: 'Okan referidos',
      admId: 324
    },
    {
      id: 11,
      routeName: "marginCommisions",
      text: "Reporte de nómina",
      name: "Reporte de nómina",
      count: true,
      subItems: [],
      icon: "mdi-chart-box-outline",
      img: origin + "/icon/comission.svg",
      classIcon: "commision-while",
      show: true,
      admName: 'Okan margen de comisiones',
      admId: 354
    },
    {
      id: 11,
      routeName: "laborRelations",
      text: "Relaciones laborales",
      name: "Relaciones laborales",
      count: true,
      subItems: [],
      icon: "mdi-chart-box-outline",
      img: origin + "/icon/comission.svg",
      classIcon: "commision-while",
      show: false, 
      admName: 'Okan margen de comisiones',
      admId: 464
    },
    // {
    //     id: 4,
    //     routeName: "chats",
    //     text: "Chat",
    //     count: true,
    //     subItems: [],
    //     icon: "mdi-chat"
    // },
    // {
    //   id: 8,
    //   routeName: "ocupation",
    //   text: "Ocupación",
    //   count: true,
    //   subItems: [],
    //   icon: "mdi-locker",
    //   name: "Ocupación",
    //   img: origin + "/icon/element-plus.svg",
    //   press: "",
    //   show: true,
    //   classIcon: "ocupation-while",
    //   admName: 'Okan ocupación',
    //   admId: 355
    // },
    {
      id: 13,
      routeName: "voluntaryRetirement",
      text: "Retiro voluntario",
      count: true,
      subItems: [],
      icon: "mdi-account-box-outline",
      name: "Retiro voluntario",
      img: origin + "/icon/profile-remove.svg",
      press: "",
      class: "v-step-17",
      show: true,
      classIcon: "profile-remove-while",
      admName: 'Okan retiro voluntario',
      admId: 333
    },
    {
      id: 14,
      routeName: "selection",
      text: "Atracción y selección",
      count: true,
      subItems: [],
      icon: "user-cirlce-add",
      name: "Atracción y selección",
      img: origin + "/icon/update-grey.svg",
      press: "",
      class: "v-step-17",
      show: false,   //Esteban
      classIcon: "user-cirlce-add-while",
      admId: 358
    },
    { //ESteban ajustar
      id: 16,
      routeName: "newDevelopment",
      text: "Nuevos desarrollos",
      count: true,
      subItems: [],
      icon: "user-cirlce-add", // Definir
      name: "Nuevos desarrollos",
      img: origin + "/icon/update-grey.svg",
      press: "",
      class: "v-step-17",
      show: false,   //Esteban
      classIcon: "user-cirlce-add-while",
      admId: 439 //poner despues 
    },
    { //ESteban ajustar
      id: 17,
      routeName: "newDevelopment",
      text: "Solicitudes Desarrollo IA",
      count: true,
      subItems: [],
      icon: "user-cirlce-add", // Definir
      name: "Solicitudes Desarrollo IA",
      img: origin + "/icon/update-grey.svg",
      press: "",
      class: "v-step-17",
      show: false,   //Esteban
      classIcon: "user-cirlce-add-while",
      admId: 439 //poner despues 
    },
    {
      id: 8,
      routeName: "malla",
      text: "Malla de turno",
      count: true,
      subItems: [],
      icon: "mdi-locker",
      name: "Malla de turno",
      img: origin + "/icon/element-plus.svg",
      press: "",
      show: true,
      classIcon: "ocupation-while",
      admName: 'Okan malla de turno',
      admId: 355
    },
    // { //ESteban ajustar
    //   id: 16,
    //   routeName: "processes",
    //   text: " Procesos disciplinarios",
    //   count: true,
    //   subItems: [],
    //   icon: "user-cirlce-add", // Definir
    //   name: "Procesos disciplinarios",
    //   img: origin + "/icon/update-grey.svg",
    //   press: "",
    //   class: "v-step-17",
    //   show: true,   //Esteban
    //   classIcon: "user-cirlce-add-while",
    //   admId: 358 //poner despues 
    // },
    // {
    //   id: 13,
    //   routeName: "voluntaryRetirement",
    //   text: "Retiro voluntario",
    //   count: true,
    //   subItems: [],
    //   icon: "mdi-account-box-outline",
    //   name: "Retiro voluntario",
    //   img: origin + "/icon/profile-remove.svg",
    //   press: "",
    //   class: "v-step-17",
    //   show: true,
    //   classIcon: "profile-remove-while",
    // },    
    {
      id: 15,
      routeName: "security",
      text: "Seguridad de la información",
      count: true,
      subItems: [],
      icon: "security-user",
      name: "Seguridad de la información",
      img: origin + "/icon/security-user.svg",
      press: "",
      class: "v-step-17",
      show: false,
      classIcon: "security-user-while",
      admName: 'Okan seguridad',
      admId: 335
    },
  ],
};
