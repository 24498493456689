<template>
  <div>
    <!-- DESKTOP -->
    <v-app-bar
      class="mb-xl-navbar "
      color="#FFFFFF"
      elevation="0"
      app
      style="border-bottom: 1px solid #d4dbff !important"
    >
    <div  class="navidad-navbar">
    </div>
      <v-btn v-if="!showIcon" @click="preview()" class="ml-2" small fab icon>
        <img loading="lazy" width="8" src="../assets/icon/vector-back.png" />
      </v-btn>
      <v-spacer></v-spacer>
      <div
        style="
          background: white;
          height: 100%;
          display: flex;
          align-items: center;
        "
      >
        <v-menu :close-on-content-click="true" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              :color="
                cantNotify === 0
                  ? 'transparent'
                  : getColorStatus('Notificaciones')
              "
              :content="cantNotify"
              style="width: 25px; margin-right: 20px; margin-top: 10px"
              overlap
            >
              <img
                loading="lazy"
                v-bind="attrs"
                v-on="on"
                :src="origin + '/icon/notification_2.svg'"
                width="32"
              />
            </v-badge>
          </template>

          <v-card
            width="400px"
            v-if="listNotify && Object.keys(listNotify).length == 0"
            style="max-height: 500px; overflow-y: hidden; overflow-x: hidden"
          >
            <v-alert dense text type="info" class="my-4 mx-4">
              No hay notificaciones disponibles
            </v-alert>
          </v-card>
          <v-card
            width="410px"
            v-else
            v-model="flagNotify"
            style="max-height: 500px; overflow-y: auto; overflow-x: hidden"
          >
            <div class="pb-0 d-flex align-center justify-space-between">
              <v-card-title class="pb-0 card-title">
                <b> Notificaciones recientes </b>
              </v-card-title>
              <v-btn
                @click="goNotifications()"
                small
                rounded
                class="mr-4 mt-4 navbar-button"
              >
                Ver todas
              </v-btn>
            </div>
            <div v-for="not in listNotifyToView" :key="not.id">
              <div
                class="d-flex justify-space-between ma-4 notifications-card cursor"
                @click="redirectNotification(not.redirect, not.case_number)"
              >
                <!-- @click="
                not.redirect &&
                  redirectNotification(not.redirect, not.case_number)
              " -->
                <div class="notifications-name ma-2">
                  <p class="mb-0 text-left">
                    <b> N° de notificación: {{ not.case_number }} </b>
                  </p>
                  <p class="mb-0 text-left">
                    {{ not.message }}
                  </p>
                </div>
                <div class="d-flex justify-center align-center">
                  <div
                    v-if="not.view == 0"
                    class="circle mr-3 rounded-circle"
                  ></div>
                </div>
              </div>
              <v-divider class="ma-4"></v-divider>
            </div>
          </v-card>
        </v-menu>
      </div>

      <!-- MENU DE APLICACIONES -->
      <div
        style="
          background: white;
          height: 100%;
          display: flex;
          align-items: center;
        "
      >
        <v-menu :close-on-content-click="true" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <img
              class="mr-4 mt-1 v-step-24"
              width="25"
              :src="origin + '/icon/more-Desktop.svg'"
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <template v-if="flagApps">
            <v-card
              width="340px"
              style="max-height: 500px; overflow-y: hidden; overflow-x: hidden"
            >
              <v-row class="pl-5 pr-5 pt-3" align="center">
                <v-overlay :value="loadingAll">
                  <v-progress-circular
                    :size="120"
                    color="#466BE3"
                    indeterminate
                  >
                    Cargando...
                  </v-progress-circular>
                </v-overlay>

                <v-alert
                  dense
                  text
                  type="info"
                  class="mt-6 mb-6 ml-6 mr-6 navbarUser__dialog-apps"
                >
                  Cargando...
                </v-alert>
              </v-row>
              <v-card-actions>
                <v-btn
                  class="navbar-button mb-2 mb-4"
                  x-small
                  block
                  rounded
                  @click="irApps()"
                  >Ver todas</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>

          <template v-else>
            <v-card
              width="340px"
              style="max-height: 500px; overflow-y: hidden; overflow-x: hidden"
            >
              <v-row class="pl-5 pr-5 pt-3" align="center">
                <v-overlay :value="loadingAll">
                  <v-progress-circular
                    :size="100"
                    color="#466BE3"
                    indeterminate
                  >
                    Cargando...
                  </v-progress-circular>
                </v-overlay>

                <v-alert
                  v-if="listApps.length == 0"
                  dense
                  text
                  type="info"
                  class="mt-6 mb-6 ml-6 mr-6"
                >
                  No se encontraron app frecuentes
                </v-alert>
                <template v-else v-for="app in listApps">
                  <v-col class="pb-2 pt-5 pl-3 pr-3" :key="app._ID" cols="4">
                    <!-- <v-btn @click="irApp(app)" class="mb-2" icon fab>
                  <img width="40" :src="app.logo" />
                </v-btn> -->
                    <div style="display: table; width: 100%; height: 60px">
                      <a
                        style="display: table-cell; vertical-align: middle"
                        class="mb-2"
                        @click="irApp(app)"
                        :href="app.urlRedirect"
                        target="_blank"
                      >
                        <img width="40" :src="app.logo" />
                      </a>
                      <br />
                    </div>
                    <p class="text-1-lines" style="font-size: 13px">
                      {{ app.nombre_app }}
                    </p>
                  </v-col>
                </template>

              </v-row>
              <v-card-actions>
                <v-btn
                  class="navbar-button mb-2 mb-4"
                  x-small
                  @click="irApps()"
                  block
                  rounded
                  >Ver todas</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-menu>
      </div>
      <div class="d-flex pt-1" style="background: white">
        <v-avatar
          @click="irProfile()"
          class="mr-6 cursor border-profile-img d-flex justify-center align-center"
        >
          <img loading="lazy" :src="img" alt="Foto de perfil" />
        </v-avatar>
        <v-card
          elevation="0"
          class="d-flex flex-column pt-2"
          style="width: 200px"
        >
          <div>
            <v-overlay :value="userLoader" :absolute="true" opacity="0">
              <v-progress-circular :size="40" color="#3957B9" indeterminate>
              </v-progress-circular>
            </v-overlay>
          </div>
          <template v-if="userLoader">
            <p>Cargando...</p>
          </template>
          <template v-else>
            <p class="mb-0 sidebar__user-name text-1-lines">
              <strong>
                {{ userName }}
              </strong>
            </p>
            <p class="mb-0 sidebar__user-profile text-1-lines">
              {{ shortProfile }}
            </p>
          </template>
        </v-card>
      </div>
    </v-app-bar>
    <!-- MOBILE -->
    <v-app-bar
      prominent
      class="mb-sm-navbar sm-navbar"
      color="#F2F4FF"
      elevation="4"
      app
    >
      <v-row class="p-0 m-0">
        <v-col class="px-0 mx-0 pb-2 pt-6 my-0 profile-picture" cols="2">
          <v-avatar
            @click="irProfile()"
            size="40"
            class="mr-2 mt-0 cursor border-profile-img v-step-50"
          >
            <img :src="img" alt="Foto de perfil" />
          </v-avatar>
        </v-col>
        <v-col class="px-2 mx-0 pb-2 pt-6 my-0" cols="10">
          <div class="d-flex justify-space-between">
            <p class="txt-nav-title text-1-1-lines">HOLA, {{ name }}</p>
            <v-badge
              :color="
                cantNotify === 0
                  ? 'transparent'
                  : getColorStatus('Notificaciones')
              "
              :content="cantNotify"
              style="width: 25px; margin-right: 15px; margin-top: 2px"
              overlap
            >
              <v-icon class="mt-0" @click="goNotifications()">
                mdi-bell-outline
              </v-icon>
            </v-badge>
          </div>
          <p class="txt-nav">Bienvenido a su espacio laboral</p>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
import Api from "@/utils/api";
import moment from "moment";
import color from "@/mixins/color";
export default {
  mixins: [color],
  data() {
    return {
      showTooltip: false,
      showIcon: true,
      loadingAll: false,
      token: null,
      user_id: null,
      origin: window.location.origin,
      listApps: [],
      apps: [],
      fav: true,
      menu: false,
      message: false,
      cantNotify: 0,
      listNotify: [],
      listNotifyToView: [],
      flagNotify: false,
      hints: true,
      token64: null,
      img: "https://duvapi.tars.dev/storage/images/IW5XYkbqmQWGLXE81N4kqjfHnfHoWeKxST5A8tfU.png",
      infoToken: {
        document: "",
      },
      name: "",
      userInfo: {},
      flagApps: true,
      userName: "",
      shortProfile: "",
      comebackFlag: true,
      userLoader: true,
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "breadcrumbs_dashboard",
        },
        {
          text: "Link 1",
          disabled: false,
          href: "breadcrumbs_link_1",
        },
        {
          text: "Link 2",
          disabled: true,
          href: "breadcrumbs_link_2",
        },
      ],
    };
  },
  computed: {
    count() {
      let flag = this.$store.getters.getNofitications;
      let flagStore = this.$store.getters.getCountNotifications;
      if (flag) {
        this.getListNotify();
        this.getCantNotify();
        this.$store.dispatch("updateNofi");
      }
      if (flagStore) {
        this.getListNotify();
        this.getCantNotify();
        this.$store.dispatch("updateCountNotifications");
      }
    },
    getAllApps() {
      let allApps = this.$store.getters.getApps;
      let user;
      if (this.$store.getters.getUser) {
        let currentUser = this.$store.getters.getUser;
        let name = currentUser.name.split(" ")[0];
        let lastname = currentUser.lastname.split(" ")[0];
        let fullName = name + " " + lastname;
        this.userName = this.toTitleCase(fullName);
        let profile = currentUser.profile.name.split("|")[0];
        this.shortProfile = this.toTitleCase(profile);
        user = this.$store.getters.getUser.document_number;
      } else {
        user = sessionStorage.getItem("document_number");
      }
      if (user && allApps.message) {
        this.getApps(user, allApps.data);
        this.userLoader = false;
      } else {
        this.userLoader = true;
      }
    },
    userProfile() {
      let userProfileFlag = this.$store.getters.getUserProfile;
      if (userProfileFlag) {
        this.getImg();
        this.$store.dispatch("updateUserProfile");
      }
    }
  },
  methods: {
    irHelp() {
      this.$router.push({ name: "helpHello" });
    },
    preview() {
      if (this.$route.name == "newsDetails") {
        this.$router.push({ name: "home" });
      } else {
        this.$router.go(-1);
      }
    },
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");
        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0].toUpperCase();
          } else {
            this.name = nameList[1].toUpperCase();
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    async irApp(app) {
      const user = await sessionStorage.getItem("user_id");
      var cc = this.infoToken.document;
      let dispositivo;
      if (screen.width < 1024) {
        dispositivo = "Mobile";
      } else {
        dispositivo = "Desktop";
      }
      const record = {
        userid: cc,
        appid: app._ID,
        appname: app.nombre_app,
        date: moment().format("YYYY-MM-DD"),
        dispositivo,
      };

      Api.noAuth()
        .saveAppActivity(record)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
          }
          // var token = `?token=${this.token64}`;
          // var url = app.url.replace("?token=", token);
          // window.open(url, "_black").focus();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    irApps() {
      this.$router.push({ name: "apps" });
    },
    irProfile() {
      this.$router.push({ name: "profile" });
    },
    deleteNotify() {
      this.flagNotify = !this.flagNotify;
      if (!this.flagNotify) {
        this.listNotify = [];
        this.cantNotify = 0;
        Api.noAuth()
          .delListNotify(this.user_id)
          .then((resp) => resp.json())
          .then(async (data) => {
            //this.listNotify = data.data
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async getCantNotify() {
      this.listApps = [];
      Api.noAuth()
        .getCantNotify(this.infoToken.document)
        .then((resp) => resp.json())
        .then(async (data) => {
          this.cantNotify = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getListNotify() {
      this.listApps = [];
      Api.noAuth()
        .getListNotify(this.infoToken.document)
        .then((resp) => resp.json())
        .then(async (data) => {
          this.listNotify = data.data;
          this.listNotifyToView = await data.data.slice(0, 5);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getApps(document, apps) {
      this.flagApps = false;
      this.loadingAll = true;
      this.listApps = [];
      await Api.noAuth()
        .getAppActivity(document)
        .then((resp) => resp.json())
        .then(async (datas) => {
          this.listApps = [];
          if (datas.cod == 0) {
            var frecuently = datas.data;
            for (let i = 0; i < apps.length; i++) {
              for (let j = 0; j < 9; j++) {
                if (frecuently[j]) {
                  if (apps[i].es_un_modulo != "true") {
                    if (apps[i]._ID == frecuently[j].appid) {
                      var token = `?token=${this.token64}`;
                      var url = apps[i].url.replace("?token=", token);
                      apps[i].urlRedirect = url;
                      this.listApps.push(apps[i]);
                    }
                  }
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
          this.$store.dispatch("updateAppActivity", this.listApps);
        });
    },
    pickHighest(obj, num) {
      let highApps = [];
      let testObj = obj;

      for (const item in obj) {
        let se = item;
        if (Object.keys(highApps).length < 9) {
          for (const testItem in testObj) {
            if (
              obj[item].logs < testObj[testItem].logs &&
              testObj[testItem].flag == 0
            ) {
              se = testItem;
            }
          }
          if (obj[se].flag == 0) {
            obj[se].flag = 1;
            highApps.push(obj[se]);
          }
        }
      }

      return highApps;
    },
    getImg() {
      Api.Auth()
        .getImg(this.infoToken.document)
        .then((res) => {
          if (res.data.cod == 0) {
            this.img = res.data.data.img;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goNotifications() {
      // redireccionar
      this.$router.push({ name: "notifications" });
    },
    redirectNotification(name, case_number) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.token64,
        },
      };
      let data = {
        notification_id: case_number,
      };
      Api.Auth()
        .updateNotification(data, config)
        .then(() => {
          this.getCantNotify();
          this.getListNotify();
        })
        .catch((error) => {
          console.log(error);
        });
      if (name && this.$route.name !== name) {
        this.$router.push({ name });
        let allApps = this.$store.getters.getApps;
        let user = this.$store.getters.getUser.document_number;
        this.getApps(user, allApps.data);
      } else {
        this.loadingAll = true;
        setTimeout(() => {
          this.loadingAll = false;
          location.reload();
        }, 800);
      }
    },
    toTitleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
  },
  mounted() {
    this.user_id = sessionStorage.getItem("user_id");
    this.getImg();
    this.getCantNotify();
    this.getListNotify();
  },
  created() {
    this.id_profile = sessionStorage.getItem("id_profile");
    // this.img = sessionStorage.getItem("img");
    this.token = localStorage.getItem("token");
    this.token64 = localStorage.getItem("tokenB64");
    this.getToken();
    
  },
  watch: {
    $route: function (n) {
      this.showIcon = false;
      if (n.name == "home") {
        this.showIcon = true;
      }
      this.showTooltip = true;
      setTimeout(() => {
        this.showTooltip = false;
      }, 100000);
    },
    show: function (newValue, old) {
      alert(`cambia de valor`);
    },
    count: function (newCount, oldCount) {
      console.log("");
    },
    userProfile: function (newCount, oldCount) {
      console.log("");
    },
    getAllApps: function (newCount, oldCount) {
      console.log("");
    },
    comebackFlag: function (newValue, old) {
      console.log(this.$router);
    },
  },
};
</script>

<style scoped>
@import "./../assets/css/main.less";

.navidad-navbar {
  background-repeat: round;
  background-size: contain;
  height: 100%;
  width: -webkit-fill-available;
}
.in-active-no {
  background-color: aliceblue !important;
}

.active-no {
  background-color: #fff !important;
}

.cursor {
  cursor: pointer;
}

.border-profile-img {
  border: 2px solid;
  border-radius: 104px;
  border-color: #3b5bc0;
}

.mb-sm-navbar {
  display: none;
}

.card-title {
  font-family: "RobotoMedium";
}

.notifications-card {
  border-radius: 10px;
}

.notifications-name {
  width: 85%;
  font-family: "RobotoRegular";
}

.circle {
  width: 13px;
  height: 13px;
  background-color: #1867c0;
  color: #1867c0;
  border: 1px solid #fff;
}

.notifications-card:hover {
  background-color: #466be3;
  color: #fff;
}

.profile-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px !important;
}

.nav-bar__list-tutorial {
  background: transparent;
  box-shadow: none !important;
  padding: 0;
}

.navbarUser__dialog-apps {
  width: 100% !important;
}

.sidebar__user-name {
  width: 200px;
  font-family: "RobotoMedium";
  color: #353535;
  overflow: hidden;
  text-align: left;
  line-height: 1rem !important;
}
.color-tooltip {
  color: #fff;
  font-size: 18px;
  height: 50px;
}
.sidebar__user-profile {
  width: 200px;
  font-family: "RobotoMedium";
  font-style: italic;
  color: #626262;
  overflow: hidden;
  text-align: left;
}

.navbar-button {
  color: #fff;
  background-color: #466be3 !important;
}

.navbar-button:hover {
  background-color: #324ca1 !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-navbar {
    display: none;
  }
  .mb-sm-navbar {
    display: block;
    text-align: initial;
  }
  .sm-navbar {
    height: 90px !important;
    box-shadow: 2px 0px 2px rgba(62, 123, 250, 0.15),
      0px 4px 8px rgba(62, 128, 250, 0.3) !important;
    border-radius: 0px 0px 15px 15px !important;
  }
  .v-app-bar-title__content {
    width: 100% !important;
    overflow: initial;
    /* position: absolute; */
  }

  .txt-nav-title {
    font-family: "RobotoRegular";
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    color: #466be3;
    margin-bottom: 0px;
  }

  .txt-nav {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-navbar {
    display: none;
  }
  .mb-sm-navbar {
    display: block;
    text-align: initial;
  }
  .sm-navbar {
    height: 90px !important;
    box-shadow: 2px 0px 2px rgba(62, 123, 250, 0.15),
      0px 4px 8px rgba(62, 128, 250, 0.3) !important;
    border-radius: 0px 0px 15px 15px !important;
  }
  .v-app-bar-title__content {
    width: 100% !important;
    overflow: initial;
    /* position: absolute; */
  }

  .txt-nav-title {
    font-family: "RobotoRegular";
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    color: #466be3;
    margin-bottom: 0px;
  }

  .txt-nav {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-navbar {
    display: none;
  }
  .mb-sm-navbar {
    display: block;
    text-align: initial;
  }
  .sm-navbar {
    height: 90px !important;
    box-shadow: 2px 0px 2px rgba(62, 123, 250, 0.15),
      0px 4px 8px rgba(62, 128, 250, 0.3) !important;
    border-radius: 0px 0px 15px 15px !important;
  }
  .v-app-bar-title__content {
    width: 100% !important;
    overflow: initial;
    /* position: absolute; */
  }

  .txt-nav-title {
    font-family: "RobotoRegular";
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    color: #466be3;
    margin-bottom: 0px;
  }

  .txt-nav {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-navbar {
    display: none;
  }
  .mb-sm-navbar {
    display: block;
    text-align: initial;
  }
  .sm-navbar {
    height: 88px !important;
    box-shadow: 2px 0px 2px rgba(62, 123, 250, 0.15),
      0px 4px 8px rgba(62, 128, 250, 0.3) !important;
    border-radius: 0px 0px 15px 15px !important;
  }
  .v-app-bar-title__content {
    width: 100% !important;
    overflow: initial;
    /* position: absolute; */
  }

  .txt-nav-title {
    font-family: "RobotoRegular";
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    color: #466be3;
    margin-bottom: 0px;
  }

  .txt-nav {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
  }
}

.button-help {
  /* background: #466be3; */
  cursor: pointer;
  border: none;
  padding: 8px 16px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  border-radius: 100px;
}

.button-help::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -4;
  width: 100%;
  height: 90%;
  background: linear-gradient(
    60deg,
    #2adb62,
    #e000b9,
    #c8de3c,
    #dea03c,
    #e82e20,
    #dea03c,
    #e000b9
  );
  background-size: 900%;
  border-radius: 10px;
  filter: blur(8px);
  animation: glowing 20s linear infinite;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
</style>
